import React, { useState } from 'react';
import { SortDirection, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { GuideStatusIcon } from './GuideStatusIcon';
import { formatDateOnly } from '../Formatters';
import moment from 'moment';
import { StyledLink } from '../Components';
import { TableCellHead, TableCellSort } from '../components/TableComponents';
import { ColWidths, compareFn, TableCellEllipsis, TableCellIcon } from '../components/TableComponents';
import { getGuideStatusDescription } from './GuideModel';
import InfiniteScroll from 'react-infinite-scroller';
import { Guide } from './Overview';
import { GuideStatus } from '../__generated__/graphql';
import { getShortName } from '../utils/string';

function GuideName({ guide }: { guide: NonNullable<Guide> }) {
  let createUrl = (id) => `${id}`;
  return <StyledLink to={createUrl(guide.id)}>{guide.docName}</StyledLink>;
}

export function GuideStatusDisplay({ guide }: { guide: NonNullable<Guide> }) {
  return (
    <GuideStatusIcon
      status={guide.status!}
      hasRevision={guide.ongoingRevision !== null}
      hasChangesSinceLastPublish={guide.hasChangesSinceLastPublish}
    />
  );
}
type ActiveGuideColumn = GuideColumn | 'status' | 'sortEdition' | 'version' | 'lastPublishedAt';
export function ActiveGuidesTable({ guides }: { guides: Guide[] }) {
  const [numberOfItems, setNumberOfItems] = useState(50);

  const [orderBy, setOrderBy] = useState<ActiveGuideColumn>('docName');
  const [direction, setDirection] = useState<SortDirection>(false);
  const handleSortClick = (key: ActiveGuideColumn) => {
    if (orderBy === key) {
      setDirection(direction === 'asc' ? 'desc' : 'asc');
    } else {
      setOrderBy(key);
      setDirection('asc');
    }
  };

  const filteredGuides = guides.filter((x) => x!.status === GuideStatus.Active);

  const comparer = getCompareFn(orderBy, direction);

  const filteredAndSortedGuides = [...filteredGuides].sort(comparer);

  const ActiveColumnHeader = ({ colKey, inputProps, children }: { colKey: ActiveGuideColumn; inputProps?: any; children?: any }) => (
    <TableCellSort inputProps={{ ...inputProps }} active={orderBy === colKey} sortDirection={direction} onClick={() => handleSortClick(colKey)}>
      {children}
    </TableCellSort>
  );

  return (
    <Table size="small" sx={{ position: 'relative' }}>
      <ColWidths widths={[60, 120, null, null, 150, 60, 100, 100]}></ColWidths>
      <TableHead>
        <TableRow>
          <ActiveColumnHeader colKey="status">Status</ActiveColumnHeader>
          <ActiveColumnHeader colKey="docName">Nummer</ActiveColumnHeader>
          <ActiveColumnHeader colKey="title">Tittel</ActiveColumnHeader>
          <ActiveColumnHeader colKey="mainCategoryTitle">Fagområde</ActiveColumnHeader>
          <ActiveColumnHeader colKey="sortEdition">Utgave</ActiveColumnHeader>

          <ActiveColumnHeader colKey="version">Versjon</ActiveColumnHeader>
          <ActiveColumnHeader colKey="lastPublishedAt">Sist endret</ActiveColumnHeader>
          <ActiveColumnHeader colKey="ownerName">Ansvarlig</ActiveColumnHeader>
        </TableRow>
      </TableHead>
      <InfiniteScroll element="tbody" loadMore={() => setNumberOfItems(numberOfItems + 50)} hasMore={numberOfItems < filteredAndSortedGuides.length}>
        {filteredAndSortedGuides.slice(0, numberOfItems).map((x) => {
          if (!x) return null;
          return (
            <TableRow key={x.id}>
              <TableCellIcon>
                <GuideStatusDisplay guide={x} />
              </TableCellIcon>
              <TableCell>
                <GuideName guide={x} />
              </TableCell>
              <TableCellEllipsis title={x.docTitle ?? undefined}>{x.docTitle}</TableCellEllipsis>
              <TableCellEllipsis title={x.mainCategory?.title ?? undefined}>{x.mainCategory?.title}</TableCellEllipsis>
              <TableCell>{x.edition}</TableCell>
              <TableCell>{x.version}</TableCell>
              <TableCell>{formatDateOnly(x.lastPublishedAt)}</TableCell>
              <TableCellEllipsis title={x.owner?.name ?? undefined}>{getShortName(x.owner?.name)}</TableCellEllipsis>
            </TableRow>
          );
        })}
      </InfiniteScroll>
    </Table>
  );
}

export function UnpublishedGuidesTable({ guides }: { guides: Guide[] }) {
  const [orderBy, setOrderBy] = useState<ActiveGuideColumn>('docName');
  const [direction, setDirection] = useState<SortDirection>(false);
  const handleSortClick = (key: ActiveGuideColumn) => {
    if (orderBy === key) {
      setDirection(direction === 'asc' ? 'desc' : 'asc');
    } else {
      setOrderBy(key);
      setDirection('asc');
    }
  };

  const filteredGuides = guides.filter((x) => x!.status === GuideStatus.Active && x!.hasChangesSinceLastPublish);

  const comparer = getCompareFn(orderBy, direction);

  const filteredAndSortedGuides = [...filteredGuides].sort(comparer);

  const ColumnHeader = ({ colKey, inputProps, children }: { colKey: ActiveGuideColumn; inputProps?: any; children?: any }) => (
    <TableCellSort inputProps={{ ...inputProps }} active={orderBy === colKey} sortDirection={direction} onClick={() => handleSortClick(colKey)}>
      {children}
    </TableCellSort>
  );

  return (
    <Table size="small" sx={{ position: 'relative' }}>
      <ColWidths widths={[60, 120, null, null, 100]}></ColWidths>
      <TableHead>
        <TableRow>
          <TableCellHead>Status</TableCellHead>
          <ColumnHeader colKey="docName">Nummer</ColumnHeader>
          <ColumnHeader colKey="title">Tittel</ColumnHeader>
          <ColumnHeader colKey="mainCategoryTitle">Fagområde</ColumnHeader>
          <ColumnHeader colKey="ownerName">Ansvarlig</ColumnHeader>
        </TableRow>
      </TableHead>
      <TableBody>
        {filteredAndSortedGuides.map((x) => {
          if (!x) return null;
          return (
            <TableRow key={x.id}>
              <TableCellIcon>
                <GuideStatusDisplay guide={x} />
              </TableCellIcon>
              <TableCell>
                <GuideName guide={x} />
              </TableCell>
              <TableCellEllipsis title={x.docTitle ?? undefined}>{x.docTitle}</TableCellEllipsis>
              <TableCellEllipsis title={x.mainCategory?.title ?? undefined}>{x.mainCategory?.title}</TableCellEllipsis>
              <TableCellEllipsis title={x.owner?.name ?? undefined}>{getShortName(x.owner?.name)}</TableCellEllipsis>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
}

type GuideColumn = 'docName' | 'title' | 'mainCategoryTitle' | 'ownerName';
export function PlannedGuidesTable({ guides }: { guides: Guide[] }) {
  const [orderBy, setOrderBy] = useState<GuideColumn>('docName');
  const [direction, setDirection] = useState<SortDirection>(false);
  const handleSortClick = (key: GuideColumn) => {
    if (orderBy === key) {
      setDirection(direction === 'asc' ? 'desc' : 'asc');
    } else {
      setOrderBy(key);
      setDirection('asc');
    }
  };

  const filteredGuides = guides.filter((x) => x!.status === GuideStatus.Planned);

  const comparer = getCompareFn(orderBy, direction);

  const filteredAndSortedGuides = [...filteredGuides].sort(comparer);

  const ColumnHeader = ({ colKey, inputProps, children }: { colKey: GuideColumn; inputProps?: any; children?: any }) => (
    <TableCellSort inputProps={{ ...inputProps }} active={orderBy === colKey} sortDirection={direction} onClick={() => handleSortClick(colKey)}>
      {children}
    </TableCellSort>
  );

  return (
    <Table size="small" sx={{ position: 'relative' }}>
      <ColWidths widths={[60, 120, null, null, 100]}></ColWidths>
      <TableHead>
        <TableRow>
          <TableCellHead>Status</TableCellHead>
          <ColumnHeader colKey="docName">Nummer</ColumnHeader>
          <ColumnHeader colKey="title">Tittel</ColumnHeader>
          <ColumnHeader colKey="mainCategoryTitle">Fagområde</ColumnHeader>
          <ColumnHeader colKey="ownerName">Ansvarlig</ColumnHeader>
        </TableRow>
      </TableHead>
      <TableBody>
        {filteredAndSortedGuides.map((x) => {
          if (!x) return null;
          return (
            <TableRow key={x.id}>
              <TableCellIcon>
                <GuideStatusDisplay guide={x} />
              </TableCellIcon>
              <TableCell>
                <GuideName guide={x} />
              </TableCell>
              <TableCellEllipsis title={x.docTitle ?? undefined}>{x.docTitle}</TableCellEllipsis>
              <TableCellEllipsis title={x.mainCategory?.title ?? undefined}>{x.mainCategory?.title}</TableCellEllipsis>
              <TableCellEllipsis title={x.owner?.name ?? undefined}>{getShortName(x.owner?.name)}</TableCellEllipsis>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
}

type ExpiredGuideColumn = GuideColumn | 'archivedAt' | 'lastPublishedAt';

export function ExpiredGuidesTable({ guides }: { guides: Guide[] }) {
  const [numberOfItems, setNumberOfItems] = useState(50);
  const [orderBy, setOrderBy] = useState<ExpiredGuideColumn>('docName');
  const [direction, setDirection] = useState<SortDirection>(false);
  const handleSortClick = (key: ExpiredGuideColumn) => {
    if (orderBy === key) {
      setDirection(direction === 'asc' ? 'desc' : 'asc');
    } else {
      setOrderBy(key);
      setDirection('asc');
    }
  };

  const filteredGuides = guides.filter((x) => x!.status === GuideStatus.Expired);

  const comparer = getCompareFn(orderBy, direction);

  const filteredAndSortedGuides = [...filteredGuides].sort(comparer);

  const ColumnHeader = ({ colKey, inputProps, children }: { colKey: ExpiredGuideColumn; inputProps?: any; children?: any }) => (
    <TableCellSort inputProps={{ ...inputProps }} active={orderBy === colKey} sortDirection={direction} onClick={() => handleSortClick(colKey)}>
      {children}
    </TableCellSort>
  );
  return (
    <Table size="small" sx={{ position: 'relative' }}>
      <ColWidths widths={[60, 120, null, null, 100, 100, 120]}></ColWidths>
      <TableHead>
        <TableRow>
          <TableCellHead>Status</TableCellHead>
          <ColumnHeader colKey="docName">Nummer</ColumnHeader>
          <ColumnHeader colKey="title">Tittel</ColumnHeader>
          <ColumnHeader colKey="mainCategoryTitle">Fagområde</ColumnHeader>
          <ColumnHeader colKey="archivedAt">Tilbaketrukket</ColumnHeader>
          <ColumnHeader colKey="lastPublishedAt">Sist publisert</ColumnHeader>
          <ColumnHeader colKey="ownerName">Ansvarlig</ColumnHeader>
        </TableRow>
      </TableHead>
      <InfiniteScroll element="tbody" loadMore={() => setNumberOfItems(numberOfItems + 50)} hasMore={numberOfItems < filteredAndSortedGuides.length}>
        {filteredAndSortedGuides.map((x) => {
          if (!x) return null;
          return (
            <TableRow key={x.id}>
              <TableCellIcon>
                <GuideStatusDisplay guide={x} />
              </TableCellIcon>
              <TableCell>
                <GuideName guide={x} />
              </TableCell>
              <TableCellEllipsis title={x.docTitle ?? undefined}>{x.docTitle}</TableCellEllipsis>
              <TableCellEllipsis title={x.mainCategory?.title ?? undefined}>{x.mainCategory?.title}</TableCellEllipsis>
              <TableCell>{formatDateOnly(x.archivedAt)}</TableCell>
              <TableCell>{formatDateOnly(x.lastPublishedAt)}</TableCell>
              <TableCellEllipsis title={x.owner?.name ?? undefined}>{getShortName(x.owner?.name)}</TableCellEllipsis>
            </TableRow>
          );
        })}
      </InfiniteScroll>
    </Table>
  );
}

type CompareFn = (a: Guide, b: Guide) => number;

function getCompareFn<T extends ActiveGuideColumn | ExpiredGuideColumn>(orderBy: T, direction: SortDirection): CompareFn {
  const fn = (getter: (model: Guide) => any) => compareFn<Guide>(getter, direction);
  if (orderBy === 'status')
    return fn((x) => (x ? getGuideStatusDescription(x.status!, x.hasChangesSinceLastPublish, x.ongoingRevision !== null) : null));
  if (orderBy === 'lastPublishedAt') return fn((x) => (x?.lastPublishedAt ? moment(x.lastPublishedAt) : null));
  if (orderBy === 'archivedAt') return fn((x) => (x?.archivedAt ? moment(x.archivedAt) : null));
  if (orderBy === 'sortEdition') {
    return fn((x) => {
      // Assuming that x.edition is in the format "Januar 2022"
      const dateValue = x?.edition ? moment(x.edition, 'MMMM YYYY') : null;
      return dateValue;
    });
  }
  return fn((x) => (x ? x[orderBy as string] : null));
}
