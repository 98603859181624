import React, { FC, useState } from 'react';
import { Typography, Tabs, Tab, Grid2 as Grid, FormControlLabel, Switch, Autocomplete, TextField, Stack } from '@mui/material';
import { SummaryTable } from './SummaryTable';
import { ResourceTable } from './ResourceTable';
import { REVISIONS } from './resources.graphql';
import { useSelector, useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import LoadingSpinner from '../LoadingSpinner';
import { selectResourceFilter, setIgnoredRoles } from './filterSlice';
import { useAuth } from 'oidc-react';
import { StyledPaper } from '../theme';
import { getAllUsersIdsWithRolesForRevision, getAllUsersWithRoles, Revision, roles, User } from './resourceUtils';

export const Overview: FC = () => {
  const auth = useAuth();
  const userId = auth.userData?.profile.sub;
  const [searchParams] = useSearchParams();
  const showMe = searchParams.get('me') === '1';

  const [selectedUserIds, setSelectedUsersIds] = useState<string[]>(showMe && userId ? [userId] : []);

  const { data, loading } = useQuery(REVISIONS);
  const revisions = data?.revisions;
  const users = data?.users;

  const selectedUsers = selectedUserIds.map((id) => users?.find((u) => u?.id === id)).filter((u) => u);

  return (
    <StyledPaper>
      <Stack>
        <Typography variant="h5" gutterBottom>
          Anvisninger under revisjon
        </Typography>
        <Autocomplete
          multiple
          value={selectedUsers}
          options={users ?? []}
          limitTags={3}
          onChange={(_, value) => setSelectedUsersIds(value.filter((x) => !!x).map((u) => u.id))}
          getOptionLabel={(option) => option?.name ?? ''}
          renderInput={(params) => <TextField {...params} label="Ressurser" variant="standard" />}
        />
        {loading && <LoadingSpinner />}
        {revisions && revisions.length === 0 && <Typography variant="subtitle1">Ingen anvisninger</Typography>}
        {revisions && revisions.length > 0 && <RevisionList revisions={revisions} selectedUsers={selectedUsers} />}
      </Stack>
    </StyledPaper>
  );
};

const RevisionList: FC<{ revisions: Revision[]; selectedUsers: User[] }> = ({ revisions, selectedUsers }) => {
  const [tab, setTab] = React.useState(0);
  const { ignoredRoles } = useSelector(selectResourceFilter);
  const userIds = selectedUsers.map((x) => x?.id);

  const filteredRevisions =
    selectedUsers.length > 0
      ? revisions.filter((revision) =>
          getAllUsersIdsWithRolesForRevision(revision).some((r) => !ignoredRoles.includes(r?.role ?? '') && userIds.includes(r?.user?.id ?? '')),
        )
      : revisions;

  return (
    <>
      <Filters revisions={revisions} selectedUsers={userIds} />
      <Tabs value={tab} onChange={(_, val) => setTab(val)} style={{ marginTop: '35px' }}>
        <Tab label="Oversikt" />
        <Tab label="Opptelling" />
      </Tabs>
      {tab === 0 && <ResourceTable revisions={filteredRevisions} selectedUsers={userIds} />}
      {tab === 1 && <SummaryTable revisions={filteredRevisions} selectedUsers={selectedUsers} />}
    </>
  );
};

const Filters: FC<{ revisions: Revision[]; selectedUsers: string[] }> = ({ revisions, selectedUsers }) => {
  const { ignoredRoles } = useSelector(selectResourceFilter);
  const dispatch = useDispatch();

  const userIds = selectedUsers;
  const allUsersWithRoles = getAllUsersWithRoles(revisions);
  const rolesWithSelectedUsers = allUsersWithRoles.filter((r) => userIds.includes(r?.user?.id ?? ''));
  const distinctRoles = rolesWithSelectedUsers.map((x) => x.role).filter((v, i, a) => a.indexOf(v) === i);

  const toggleRole = (role: string) => {
    if (ignoredRoles.includes(role)) {
      dispatch(setIgnoredRoles(ignoredRoles.filter((x) => x !== role)));
    } else {
      dispatch(setIgnoredRoles([...ignoredRoles, role]));
    }
  };

  if (distinctRoles.length === 0) return null;

  return (
    <div>
      <ul style={{ listStyleType: 'none', paddingLeft: 12 }}>
        {distinctRoles.map((role) => {
          if (!role) return null;
          return (
            <li key={role} style={{ float: 'left' as 'left' }}>
              <div style={{ fontFamily: 'Arial, Helvetica, sans-serif', float: 'left', fontSize: 14 }}>
                <FormControlLabel
                  control={<Switch onChange={() => toggleRole(role)} checked={!ignoredRoles.includes(role)} />}
                  label={roles[role].label}
                />
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
