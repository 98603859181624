import React, { useRef } from 'react';
import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import LoadingSpinner from '../LoadingSpinner';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Grid2,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { useMutation, useQuery } from '@apollo/client';
import {
  ADD_TOPIC,
  DELETE,
  GET_MEETING,
  GET_MEETINGS,
  GET_REVISIONS,
  MOVE_TOPIC,
  REMOVE_TOPIC,
  UPDATE_CONFIRMATION_DATE,
  UPDATE_DATE,
  UPDATE_INVITATION_DATE,
  UPDATE_TOPIC_STATUS,
} from './committeeMeetings.graphql';
import { GetCommitteeMeetingQuery, GuideStatus, RevisionRoles } from '../__generated__/graphql';
import { BackButton, StyledLink } from '../Components';
import { DatePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { ConfirmDialog, ConfirmDialogProps } from '../dialogs/ConfirmDialog';
import { useModal } from '../dialogs/useModal';
import { Add, Delete, South } from '@mui/icons-material';
import { formatDateOnly } from '../Formatters';
import { ColWidths, TableCellHead, TableCellIcon, TableCellEllipsis } from '../components/TableComponents';
import { StyledPaper } from '../theme';

export const Details: FC = () => {
  const { id } = useParams();

  const { data, loading } = useQuery(GET_MEETING, { variables: { id: id } });
  if (loading) return <LoadingSpinner />;
  if (!data?.committeeMeeting) return <Typography>Fant ikke møtet</Typography>;
  return <MeetingDetails meeting={data.committeeMeeting} />;
};

type CommitteeMeeting = NonNullable<GetCommitteeMeetingQuery['committeeMeeting']>;
const MeetingDetails: FC<{ meeting: CommitteeMeeting }> = ({ meeting }) => {
  const navigate = useNavigate();
  const [deleteMeeting, { loading: deleting }] = useMutation(DELETE, { variables: { input: { id: meeting.id } } });

  const dialogProps: ConfirmDialogProps = {
    title: 'Slett møte',
    message: 'Er du sikker på at du vil slette møtet?',
    submitText: 'Slett',
    cancelText: 'Avbryt',
  };
  const confirmModal = useModal<ConfirmDialogProps, boolean>({ data: dialogProps });

  const doDeleteMeeting = async () => {
    const confirmed = await confirmModal.open();
    if (confirmed) {
      await deleteMeeting();
      navigate('/meeting');
    }
  };

  return (
    <div>
      <Box display="grid" gridTemplateColumns="fit-content(25%) fit-content(40%) 1fr" alignItems="center" mr={1}>
        <BackButton />
        <Typography variant="h5">Utvalgsmøte {formatDateOnly(meeting.date, 'DD.MMMM')}</Typography>
        <div style={{ justifyContent: 'end', display: 'flex' }}>
          <Button
            color="error"
            variant="contained"
            onClick={doDeleteMeeting}
            disabled={deleting}
            startIcon={deleting ? <CircularProgress size={25} /> : undefined}>
            Slett
          </Button>
        </div>
      </Box>
      <Stack spacing={1}>
        <Dates meeting={meeting} />
        <Topics meeting={meeting} />
      </Stack>
      {confirmModal.isOpen && <ConfirmDialog modal={confirmModal} />}
    </div>
  );
};

const Dates: FC<{ meeting: CommitteeMeeting }> = ({ meeting }) => {
  const [updateDate, { loading: dateUpdating }] = useMutation(UPDATE_DATE);
  const [updateInvitationDate, { loading: invitationDateUpdating }] = useMutation(UPDATE_INVITATION_DATE);
  const [updateConfirmationDate, { loading: confirmationDateUpdating }] = useMutation(UPDATE_CONFIRMATION_DATE);
  const dateFormat = 'DD.MM.YYYY';

  return (
    <div>
      <StyledPaper>
        <Stack spacing={2}>
          <Typography fontWeight="bold">Tidspunkter</Typography>
          <Grid2 container spacing={5}>
            <DatePicker
              sx={{ maxWidth: 125 }}
              format={dateFormat}
              disabled={dateUpdating}
              label="Møte"
              value={moment(meeting.date)}
              slotProps={{ textField: { variant: 'standard' } }}
              onChange={async (date, context) => {
                if (!context.validationError && date !== null) {
                  await updateDate({ variables: { input: { id: meeting.id, date: date.toISOString() } } });
                }
              }}
            />
            <DatePicker
              sx={{ maxWidth: 125 }}
              format={dateFormat}
              label="Invitasjon"
              disabled={invitationDateUpdating}
              value={moment(meeting.invitationDate)}
              slotProps={{ textField: { variant: 'standard' } }}
              onChange={async (date, context) => {
                if (!context.validationError && date !== null) {
                  await updateInvitationDate({ variables: { input: { id: meeting.id, date: date.toISOString() } } });
                }
              }}
            />
            <DatePicker
              sx={{ maxWidth: 125 }}
              format={dateFormat}
              label="Bekreftelse"
              disabled={confirmationDateUpdating}
              value={moment(meeting.confirmationDate)}
              slotProps={{ textField: { variant: 'standard' } }}
              onChange={async (date, context) => {
                if (!context.validationError && date !== null) {
                  await updateConfirmationDate({ variables: { input: { id: meeting.id, date: date.toISOString() } } });
                }
              }}
            />
          </Grid2>
        </Stack>
      </StyledPaper>
    </div>
  );
};

type GuideOption = { id: string; label: string };

const Topics: FC<{ meeting: CommitteeMeeting }> = ({ meeting }) => {
  const { data } = useQuery(GET_REVISIONS);
  const { data: meetingsdata } = useQuery(GET_MEETINGS, { variables: { from: meeting.date } });
  const [newTopic, setNewTopic] = React.useState<GuideOption | null>(null);
  const [addTopic, { loading: addingTopic }] = useMutation(ADD_TOPIC, { variables: { input: { id: meeting.id, revisionId: newTopic?.id } } });
  const [removeTopic, { loading: removingTopic }] = useMutation(REMOVE_TOPIC);
  const [updateTopicStatus, { loading: updatingTopicStatus }] = useMutation(UPDATE_TOPIC_STATUS);
  const [moveTopic, { loading: movingTopic }] = useMutation(MOVE_TOPIC);
  const searchField = useRef<HTMLInputElement>();

  const nextMeeting =
    meetingsdata?.committeeMeetings?.nodes && (meetingsdata.committeeMeetings.nodes.length ?? 0) > 0
      ? meetingsdata?.committeeMeetings?.nodes[0]
      : undefined;

  const doAddTopic = async () => {
    if (newTopic) {
      await addTopic();
      setNewTopic(null);
      searchField.current?.focus();
    }
  };

  const doRemoveTopic = async (revisionId: string) => {
    await removeTopic({ variables: { input: { id: meeting.id, revisionId } } });
  };

  const moveTopicToNextMeeting = async (revisionId: string) => {
    await moveTopic({ variables: { input: { id: meeting.id, revisionId, meetingId: nextMeeting?.id } } });
  };

  const options: GuideOption[] = data?.revisions
    ? data.revisions
        ?.filter((x) => !meeting.topics?.some((t) => t?.revision?.id === x?.id))
        .map((x) => {
          return { id: x?.id, label: `${x?.guide?.docName} ${x?.guide?.docTitle}` };
        })
    : [];
  options.sort((a, b) => a.label.localeCompare(b.label));

  const topics = meeting.topics?.slice(0) ?? [];
  topics.sort(
    (a, b) =>
      //sort first on confirmed status, then on guide guide.docName
      (a?.confirmed === b?.confirmed ? 0 : a?.confirmed ? -1 : 1) ||
      (a?.revision?.guide?.docName?.localeCompare(b?.revision?.guide?.docName ?? '') ?? 0),
  );
  return (
    <div>
      <StyledPaper>
        <Stack spacing={2}>
          <Typography fontWeight="bold">Anvisninger under revisjon</Typography>
          <Box alignItems="center" display="grid" gridTemplateColumns="1fr fit-content(20%)">
            <Autocomplete
              size="small"
              fullWidth
              disablePortal
              options={options}
              value={newTopic}
              renderInput={(params) => <TextField {...params} inputRef={searchField} label="Finn anvisning..." />}
              onChange={(_, newValue) => setNewTopic(newValue)}
            />
            <div>
              <Button disabled={!newTopic || addingTopic} onClick={doAddTopic} startIcon={addingTopic ? <CircularProgress size={25} /> : undefined}>
                Legg til
              </Button>
            </div>
          </Box>
          <Table size="small">
            <ColWidths widths={[40, 40, null, null, null, null, null, null, 40]} />
            <TableHead>
              <TableRow>
                <TableCellHead>Bekreftet</TableCellHead>
                <TableCellHead>Nummer</TableCellHead>
                <TableCellHead width="30%">Tittel</TableCellHead>
                <TableCellHead width="10%">Prosjektleder</TableCellHead>
                <TableCellHead width="10%">IKS</TableCellHead>
                <TableCellHead width="10%">Forfatter</TableCellHead>
                <TableCellHead width="10%">FKS</TableCellHead>
                <TableCellHead width="10%">Fagområde</TableCellHead>
                <TableCellHead />
              </TableRow>
            </TableHead>
            <TableBody>
              {topics.length === 0 && (
                <TableRow>
                  <TableCell colSpan={9} sx={{ color: (theme) => theme.palette.text.secondary }}>
                    Ingen anvisninger lagt til som aktuelle for møtet
                  </TableCell>
                </TableRow>
              )}
              {topics.map((x) => {
                if (!x?.revision) return null;
                const revision = x.revision;
                const guide = revision.guide;
                const categoryTitle = guide?.mainCategory ? guide.mainCategory.title : null;
                const authors = revision.authors?.map((x) => x?.name).join(', ');
                const professionQualityAssurers = revision.professionQualityAssurers?.map((x) => x?.name).join(', ');
                const projectLeads = revision.projectLeads?.map((x) => x?.name).join(', ');
                const internalQualityAssurers = revision.internalQualityAssurers?.map((x) => x?.name).join(', ');
                return (
                  <TableRow key={revision.id}>
                    <TableCell>
                      <Checkbox
                        size="small"
                        disabled={updatingTopicStatus || movingTopic || removingTopic}
                        checked={x.confirmed}
                        onChange={(_, checked) =>
                          updateTopicStatus({ variables: { input: { id: meeting.id, revisionId: revision.id, isConfirmed: checked } } })
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <StyledLink to={`/guide/${guide?.id}`}>{guide?.docName}</StyledLink>
                    </TableCell>
                    <TableCellEllipsis title={guide?.docTitle ?? ''}>{guide?.docTitle}</TableCellEllipsis>
                    <TableCellEllipsis title={projectLeads}>{projectLeads}</TableCellEllipsis>
                    <TableCellEllipsis title={internalQualityAssurers}>{internalQualityAssurers}</TableCellEllipsis>
                    <TableCellEllipsis title={authors}>{authors}</TableCellEllipsis>
                    <TableCellEllipsis title={professionQualityAssurers}>{professionQualityAssurers}</TableCellEllipsis>
                    <TableCellEllipsis title={categoryTitle ?? ''}>{categoryTitle}</TableCellEllipsis>
                    <TableCellIcon>
                      <Stack direction="row">
                        <IconButton
                          onClick={() => moveTopicToNextMeeting(revision.id)}
                          size="small"
                          disabled={!nextMeeting || movingTopic || removingTopic}
                          title="Flytt til neste møte">
                          <South fontSize="small" />
                        </IconButton>
                        <IconButton
                          onClick={() => doRemoveTopic(revision.id)}
                          size="small"
                          disabled={removingTopic || movingTopic}
                          title="Fjern anvisning">
                          <Delete fontSize="small" />
                        </IconButton>
                      </Stack>
                    </TableCellIcon>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Stack>
      </StyledPaper>
    </div>
  );
};
