import { gql } from '../__generated__';

gql(/* GraphQL */ `
  fragment RevisionUserItem on User {
    subjectId
    name
    email
  }
`);

gql(/* GraphQL */ `
  fragment ChecklistTaskItem on ChecklistTask {
    id
    name
    types
    completed
    completedAt
    completedBy
    notRelevant
  }
`);

gql(/* GraphQL */ `
  fragment ChecklistModelItem on ChecklistModel {
    id
    version
    latestVersion
    numberOfTasks
    completedTasks
    milestones {
      name
      number
      numberOfTasks
      completedTasks
      tasks {
        ...ChecklistTaskItem
      }
    }
  }
`);

gql(/* GraphQL */ `
  fragment RevisionModelItem on RevisionModel {
    id
    guideId
    type
    title
    workingTitle
    description
    freeText
    checklistId
    scheduledEndDate
    createdAt
    createdById
    professionQualityAssurers {
      id
      name
    }
    authors {
      id
      name
    }
    projectLeads {
      id: subjectId
      name
    }
    internalQualityAssurers {
      id: subjectId
      name
    }
    peerReviewers {
      id: subjectId
      name
    }
    dacs {
      id: subjectId
      name
    }
    publishingEditors {
      id: subjectId
      name
    }
    maconomyProjectNumber
    progress
    currentPhase
    guide {
      docName
      docNumber
      docTitle
      mainCategory {
        title
      }
      ownerId
    }
    createdByUser {
      name
    }
    canceledAt
    canceledByUser {
      name
    }
    completedAt
    checklist {
      ...ChecklistModelItem
    }
    commentToOrganization
  }
`);

export const REVISION_BY_ID = gql(/* GraphQL */ `
  query GetRevision($revisionId: UUID!) {
    revision(id: $revisionId) {
      ...RevisionModelItem
    }
  }
`);

export const GET_PEOPLE_FOR_ROLES = gql(/* GraphQL */ `
  query GetPeopleForRoles {
    users {
      id: subjectId
      name
    }
    subjectResources(where: { isDeleted: { eq: false } }, order: [{ name: ASC }]) {
      id
      name
    }
  }
`);

export const SET_RESPONSIBLE = gql(/* GraphQL */ `
  mutation RevisionSetResponsible($input: RevisionSetResponsibleInput!) {
    revisionSetResponsible(input: $input) {
      revisionModel {
        ...RevisionModelItem
      }
    }
  }
`);

export const UPDATE_COMMENT_TO_ORGANIZATION = gql(/* GraphQL */ `
  mutation RevisionUpdateCommentToOrganization($input: RevisionUpdateCommentToOrganizationInput!) {
    revisionUpdateCommentToOrganization(input: $input) {
      revisionModel {
        ...RevisionModelItem
      }
    }
  }
`);

export const GET_CHANGES = gql(/* GraphQL */ `
  query GetRevisionChanges($id: UUID!, $first: Int) {
    changeModel: revisionChanges(id: $id, first: $first) {
      totalCount
      nodes {
        id
        description
        createdAt
        createdBy
      }
    }
  }
`);

export const UPDATE_TASK = gql(/* GraphQL */ `
  mutation RevisionUpdateTask($input: RevisionUpdateTaskInput!) {
    revisionUpdateTask(input: $input) {
      revisionModel {
        ...RevisionModelItem
      }
    }
  }
`);

export const UPDATE_TYPE = gql(/* GraphQL */ `
  mutation RevisionUpdateType($input: RevisionUpdateTypeInput!) {
    revisionUpdateType(input: $input) {
      revisionModel {
        ...RevisionModelItem
      }
    }
  }
`);

export const GET_REVISIONS = gql(/* GraphQL */ `
  query GetAllRevisions {
    revisions(order: { scheduledEndDate: ASC }, where: { canceledAt: { eq: null } }) {
      ...RevisionModelItem
    }
  }
`);

export const UPDATE_WORKING_TITLE = gql(/* GraphQL */ `
  mutation RevisionUpdateWorkingTitle($input: RevisionUpdateWorkingTitleInput!) {
    revisionUpdateWorkingTitle(input: $input) {
      revisionModel {
        id
        workingTitle
      }
    }
  }
`);

export const UPDATE_FREE_TEXT = gql(/* GraphQL */ `
  mutation RevisionUpdateFreeText($input: RevisionUpdateFreeTextInput!) {
    revisionUpdateFreeText(input: $input) {
      revisionModel {
        id
        freeText
      }
    }
  }
`);

export const COMPLETE = gql(/* GraphQL */ `
  mutation RevisionComplete($input: RevisionCompleteInput!) {
    revisionComplete(input: $input) {
      revisionModel {
        id
        guideId
        completedAt
        completedById
      }
    }
  }
`);

export const CANCEL = gql(/* GraphQL */ `
  mutation RevisionCancel($input: RevisionCancelInput!) {
    revisionCancel(input: $input) {
      revisionModel {
        id
        canceledAt
        canceledByUser {
          name
        }
      }
    }
  }
`);

export const SET_SCHEDULED_END_DATE = gql(/* GraphQL */ `
  mutation RevisionSetScheduledEndDate($input: RevisionSetScheduledEndDateInput!) {
    revisionSetScheduledEndDate(input: $input) {
      revisionModel {
        id
        scheduledEndDate
      }
    }
  }
`);

export const UPDATE_DESCRIPTION = gql(/* GraphQL */ `
  mutation RevisionUpdateDescription($input: RevisionUpdateDescriptionInput!) {
    revisionUpdateDescription(input: $input) {
      revisionModel {
        id
        description
      }
    }
  }
`);

export const SET_MACONOMY_PROJECT_NUMBER = gql(/* GraphQL */ `
  mutation RevisionSetMaconomyProjectNumber($input: RevisionSetMaconomyProjectNumberInput!) {
    revisionSetMaconomyProjectNumber(input: $input) {
      revisionModel {
        id
        maconomyProjectNumber
      }
    }
  }
`);
