import React from 'react';
import { createRoot } from 'react-dom/client';

import { Provider } from 'react-redux';

import { AuthProvider } from 'oidc-react';
import store from './store';

import 'moment/locale/nb';

import { App } from './App';
import { unregister } from './registerServiceWorker';
import { ThemeProvider, StyledEngineProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import { theme } from './theme';
import getConfig from './env';
import 'moment/dist/locale/nb';
import FlagProvider from '@unleash/proxy-client-react';
import { UnleashContextUpdater, getUnleashConfig } from './unleash';
import { getOidcConfig } from './oidc';
import { CustomApolloProvider } from './apollo';
import { LicenseInfo } from '@mui/x-license';

LicenseInfo.setLicenseKey('15835a903b85fe0a99f2d7c354104e04Tz0xMDkxMjgsRT0xNzcyODQxNTk5MDAwLFM9cHJvLExNPXN1YnNjcmlwdGlvbixQVj1pbml0aWFsLEtWPTI=');

async function initialize() {
  const config = await getConfig();

  const unleashConfig = getUnleashConfig(config);
  const oidcConfig = getOidcConfig(config);

  const renderApp = () => (
    <FlagProvider config={unleashConfig}>
      <Provider store={store}>
        <AuthProvider {...oidcConfig}>
          <UnleashContextUpdater />
          <CustomApolloProvider config={config} oidcConfig={oidcConfig}>
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={theme}>
                <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="no">
                  <App />
                </LocalizationProvider>
              </ThemeProvider>
            </StyledEngineProvider>
          </CustomApolloProvider>
        </AuthProvider>
      </Provider>
    </FlagProvider>
  );

  let renderUnsupportedBrowser = () => (
    <div>
      <span>Internet Explorer 11 støttes ikke</span>
    </div>
  );

  let isIE11orOlder = !!(window as any).MSInputMethodContext && !!(document as any).documentMode;

  const container = document.getElementById('root');
  const root = createRoot(container!);
  root.render(isIE11orOlder ? renderUnsupportedBrowser() : renderApp());

  unregister();
}

initialize();
