import { GetRevisionsForResourcesQuery } from '../__generated__/graphql';
import { Unpacked } from '../graphQLTypes/types';
import { getShortName } from '../utils/string';

interface RoleDefinition {
  label: string;
  title: string;
}

export const roles = {
  PROJECT_LEAD: { label: 'PL', title: 'Prosjektleder' } as RoleDefinition,
  INTERAL_QUALITY_ASSURER: { label: 'IKS', title: 'Intern kvalitetssikrer' } as RoleDefinition,
  PEER_REVIEWER: { label: 'SK', title: 'Sidemannkontroll/sluttkontroll' } as RoleDefinition,
  AUTHOR: { label: 'FF', title: 'Forfatter' } as RoleDefinition,
  PROFESSION_QUALITY_ASSURER: { label: 'FKS', title: 'Faglig kvalitetssikrer' } as RoleDefinition,
  DAC: { label: 'DAK', title: 'Dac' } as RoleDefinition,
  PUBLISHING_EDITOR: { label: 'RED', title: 'Publishing Editor' } as RoleDefinition,
  OWNER: { label: 'FA', title: 'Fagansvarlig' } as RoleDefinition,
};

export interface Option {
  value: string;
  label: string;
}

export type Revision = Unpacked<GetRevisionsForResourcesQuery['revisions']>;
export type User = Unpacked<NonNullable<Revision>['projectLeads']>;

export function getRoles(revision: Revision): { role: keyof typeof roles; userIds: string[]; names: string; shortNames: string }[] {
  return Object.keys(roles).map((r) => {
    const key = r as keyof typeof roles;
    const list = getListFromRoles(revision, key);

    return {
      role: key,
      userIds: list?.map((x) => x?.id as string) ?? [],
      names: list?.map((x) => x?.name).join(',') ?? '',
      shortNames: getShortName(...(list?.map((x) => x?.name) ?? [])) ?? '',
    };
  });
}

export function getListFromRoles(revision: Revision, role: keyof typeof roles) {
  switch (role) {
    case 'AUTHOR':
      return revision?.authors;
    case 'PROFESSION_QUALITY_ASSURER':
      return revision?.professionQualityAssurers;
    default:
      return getListFromUserRoles(revision, role);
  }
}

function getListFromUserRoles(revision: Revision, role: keyof typeof roles) {
  switch (role) {
    case 'PROJECT_LEAD':
      return revision?.projectLeads;
    case 'INTERAL_QUALITY_ASSURER':
      return revision?.internalQualityAssurers;
    case 'PEER_REVIEWER':
      return revision?.peerReviewers;
    case 'DAC':
      return revision?.dacs;
    case 'OWNER':
      return revision?.guide?.owner ? [revision.guide.owner] : [];
    case 'PUBLISHING_EDITOR':
      return revision?.publishingEditors;
  }

  throw new Error(`Unknown role: ${role}`);
}

function getUserRoles(revision: Revision): { role: keyof typeof roles; users: User[] | null | undefined }[] {
  return [
    { role: 'PROJECT_LEAD', users: getListFromUserRoles(revision, 'PROJECT_LEAD') },
    { role: 'INTERAL_QUALITY_ASSURER', users: getListFromUserRoles(revision, 'INTERAL_QUALITY_ASSURER') },
    { role: 'PEER_REVIEWER', users: getListFromUserRoles(revision, 'PEER_REVIEWER') },
    { role: 'DAC', users: getListFromUserRoles(revision, 'DAC') },
    { role: 'DAC', users: getListFromUserRoles(revision, 'DAC') },
    { role: 'PUBLISHING_EDITOR', users: getListFromUserRoles(revision, 'PUBLISHING_EDITOR') },
    { role: 'OWNER', users: getListFromUserRoles(revision, 'OWNER') },
  ];
}

export function getAllUsersWithRoles(revisions: Revision[]) {
  return revisions.flatMap((r) => getAllUsersIdsWithRolesForRevision(r));
}

export function getAllUsersIdsWithRolesForRevision(revision: Revision) {
  return getUserRoles(revision).flatMap((x) =>
    x.users?.map((u) => {
      return { role: x.role, user: u };
    }),
  );
}
