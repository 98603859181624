import React, { FC } from 'react';
import { GET_PEOPLE_FOR_ROLES, SET_RESPONSIBLE } from './revisions.graphql';
import { useMutation, useQuery } from '@apollo/client';
import { Typography, ListItem, Autocomplete, TextField, Stack } from '@mui/material';
import { revisionRoleToDisplayText } from './RevisionModel';
import { Revision } from './Details';
import { GetPeopleForRolesQuery, RevisionRoles } from '../__generated__/graphql';
import { StyledPaper } from '../theme';
import { Unpacked } from '../graphQLTypes/types';

export type User = Unpacked<GetPeopleForRolesQuery['users']>;
type SubjectResource = Unpacked<GetPeopleForRolesQuery['subjectResources']>;

export const Roles: FC<{ revision: Revision; history: boolean; validate?: boolean }> = ({ revision, history, validate }) => {
  return (
    <StyledPaper>
      <Typography variant="h5" component="h3" gutterBottom>
        Roller
      </Typography>
      <RolesContextProvider revision={revision}>
        <Stack spacing={2}>
          <Role role={RevisionRoles.ProjectLead} readonly={history} />
          <Role role={RevisionRoles.InteralQualityAssurer} readonly={history} />
          <Role role={RevisionRoles.PeerReviewer} readonly={history} />
          <Role role={RevisionRoles.Author} readonly={history} />
          <Role role={RevisionRoles.ProfessionQualityAssurer} readonly={history} />
          <Role role={RevisionRoles.Dac} readonly={history} />
          <Role role={RevisionRoles.PublishingEditor} readonly={history} />
        </Stack>
      </RolesContextProvider>
    </StyledPaper>
  );
};

const Role: FC<{ role: RevisionRoles; readonly?: boolean }> = ({ role, readonly }) => {
  const label = revisionRoleToDisplayText(role);
  const { getResponsibleForRole, getPeopleForRole, updateResponsible } = useRolesContext();
  const responsible = getResponsibleForRole(role);
  const people = getPeopleForRole(role);

  if (readonly) return <Item label={label} value={peopleToString(responsible)} />;

  return <PeopleSelect label={label} selectedPeople={responsible} people={people ?? []} onChange={(selected) => updateResponsible(selected, role)} />;
};

type People = User | SubjectResource;

const PeopleSelect: FC<{
  label: string;
  people: People[];
  selectedPeople: People[] | null | undefined;
  onChange(selected: People[]): void;
  required?: boolean;
}> = ({ people, selectedPeople, onChange, required, label }) => {
  const error = (!selectedPeople || selectedPeople.length === 0) && required;

  return (
    <Autocomplete
      multiple
      value={selectedPeople ?? undefined}
      options={people}
      limitTags={3}
      onChange={(_, value) => onChange(value)}
      getOptionLabel={(option) => option?.name ?? ''}
      renderInput={(params) => <TextField {...params} label={label} error={error} variant="standard" />}
    />
  );
};

const Item: FC<{ label: string; value: string | undefined }> = ({ label, value }) => {
  return (
    <ListItem sx={{ paddingTop: 1, paddingBottom: 1 }}>
      <div style={{ flex: 0.35 }}>
        <Typography variant="subtitle1">{label}: </Typography>
      </div>
      <div style={{ flex: 0.65 }}>{value ?? ''}</div>
    </ListItem>
  );
};

function peopleToString(people: People[] | null | undefined) {
  return people?.map((x) => x?.name).join(', ');
}

function getPeopleForRole(revision: Revision, role: RevisionRoles): People[] | null | undefined {
  if (!revision) return null;
  switch (role) {
    case RevisionRoles.ProjectLead:
      return revision.projectLeads;
    case RevisionRoles.InteralQualityAssurer:
      return revision.internalQualityAssurers;
    case RevisionRoles.PeerReviewer:
      return revision.peerReviewers;
    case RevisionRoles.Author:
      return revision.authors;
    case RevisionRoles.ProfessionQualityAssurer:
      return revision.professionQualityAssurers;
    case RevisionRoles.Dac:
      return revision.dacs;
    case RevisionRoles.PublishingEditor:
      return revision.publishingEditors;
  }
  throw new Error(`Unknown role: ${role}`);
}

interface RolesContext {
  getResponsibleForRole: (role: RevisionRoles) => People[] | null | undefined;
  updateResponsible: (responsibleUsers: People[], role: RevisionRoles) => void;
  getPeopleForRole: (role: RevisionRoles) => People[] | null | undefined;
}

const RolesContextInstance = React.createContext<RolesContext | undefined>(undefined);

const useRolesContext = (): RolesContext => {
  const context = React.useContext(RolesContextInstance);
  if (!context) {
    throw new Error('useRolesContext must be used within a RolesContextProvider');
  }
  return context;
};

export const RolesContextProvider: FC<{ children: React.ReactNode; revision: Revision }> = ({ children, revision }) => {
  const [setResponsible] = useMutation(SET_RESPONSIBLE);
  const updateResponsible = (responsibleUsers: People[], role: RevisionRoles) =>
    setResponsible({ variables: { input: { id: revision?.id, role, responsibleSubjectIds: responsibleUsers.map((x) => x?.id) } } });
  const { data } = useQuery<GetPeopleForRolesQuery>(GET_PEOPLE_FOR_ROLES);

  const users = data?.users ?? [];
  const subjectResources = data?.subjectResources ?? [];

  const context: RolesContext = {
    getResponsibleForRole: (role: RevisionRoles) => getPeopleForRole(revision, role),
    updateResponsible,
    getPeopleForRole: (role: RevisionRoles) =>
      [RevisionRoles.Author, RevisionRoles.ProfessionQualityAssurer].includes(role) ? subjectResources : users,
  };

  return <RolesContextInstance.Provider value={context}>{children}</RolesContextInstance.Provider>;
};
