import React, { FC } from 'react';
import { styled } from '@mui/material/styles';
import { IconButton } from '@mui/material';
import { GridApiPro, gridFilteredSortedRowIdsSelector } from '@mui/x-data-grid-pro';
import { BacklogItem } from './types';
import { utils, writeFile } from 'xlsx';
import { getGuideStatusDescription } from '../guides/GuideModel';

const PREFIX = 'ExcelExport';

const classes = {
  excelButton: `${PREFIX}-excelButton`,
  excelIcon: `${PREFIX}-excelIcon`,
};

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  [`&.${classes.excelButton}`]: {
    position: 'absolute' as 'absolute',
    right: theme.spacing(4),
    top: theme.spacing(12),
  },

  [`& .${classes.excelIcon}`]: {
    width: 24,
    height: 24,
  },
}));

export const ExcelExport: FC<{ apiRef: React.MutableRefObject<GridApiPro> }> = ({ apiRef }) => {
  const handleExport = () => {
    const filteredSortedRowIds = gridFilteredSortedRowIdsSelector(apiRef);
    const rows = filteredSortedRowIds.map((id) => apiRef.current.getRow(id) as BacklogItem);
    exportFile(rows);
  };

  return (
    <StyledIconButton className={classes.excelButton} onClick={handleExport} title="Eksport til Excel" size="large">
      <img src="/icons8-microsoft-excel.svg" className={classes.excelIcon} alt="Excel icon" />
    </StyledIconButton>
  );
};

function exportFile(items?: BacklogItem[]) {
  if (!items) {
    return;
  }

  const headings: string[] = [
    'Status',
    'Nummer',
    'Tittel',
    'Siste versjon',
    'Visninger',
    'Prioritert',
    'Prioritert begrunnelse',
    'Fagområde',
    'Merknader',
    'Oppgaver',
    'Forfatter',
    'Prosjektleder',
    'Kommentarer',
  ];

  const data = items.map((item) => {
    if (!item) return [];
    return [
      getGuideStatusDescription(item.status!, item.hasChangesSinceLastPublish, item.ongoingRevision !== null),
      item.docName,
      item.docTitle,
      item.planningScores?.edition?.value,
      item.planningScores?.pageViews?.value,
      item.planningScores?.needForRevision?.value,
      item.needForRevisionComment,
      item.mainCategory?.title,
      item.notices
        ?.filter((x) => !x?.deletedAt)
        .map((notice) => notice!.notice)
        .join('\n'),
      item.tasks?.map((task) => task!.title).join('\n'),
      item.authors?.map((author) => author!.name).join(','),
      item.projectLeader,
      item.comments?.map((comment) => comment!.comment).join('\n'),
    ];
  });
  data.unshift(headings);
  const sheet = utils.aoa_to_sheet(data);
  const workbook = utils.book_new();
  utils.book_append_sheet(workbook, sheet, 'Planlegging');
  writeFile(workbook, 'planlegging.xlsx');
}
