import { gql } from '../__generated__';

export const REVISIONS = gql(/* GraphQL */ `
  query GetRevisionsForResources {
    revisions(where: { and: [{ completedAt: { eq: null } }, { canceledAt: { eq: null } }] }) {
      id
      scheduledEndDate
      progress
      authors {
        id
        name
      }
      professionQualityAssurers {
        id
        name
      }
      projectLeads {
        id: subjectId
        name
      }
      internalQualityAssurers {
        id: subjectId
        name
      }
      peerReviewers {
        id: subjectId
        name
      }
      dacs {
        id: subjectId
        name
      }
      publishingEditors {
        id: subjectId
        name
      }
      currentPhase
      guide {
        id
        docName
        docTitle
        owner {
          id: subjectId
          name
        }
        mainCategory {
          title
        }
      }
    }

    users {
      id: subjectId
      name
    }
  }
`);
