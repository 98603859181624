import React, { FC } from 'react';
import { Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import { RoleHeaders, StickyTableCell } from './RoleHeaders';
import { getAllUsersIdsWithRolesForRevision, getAllUsersWithRoles, getListFromRoles, Revision, roles } from './resourceUtils';
import { Unpacked } from '../graphQLTypes/types';
import { getShortName } from '../utils/string';

interface Props {
  revisions: Revision[];
  selectedUsers: User[];
}

type User = Unpacked<NonNullable<Revision>['projectLeads']>;
export const SummaryTable: FC<Props> = ({ revisions, selectedUsers }) => {
  const users = selectedUsers.length > 0 ? selectedUsers : getUsersFromRevisions(revisions);
  return (
    <Table padding="none">
      <TableHead>
        <TableRow>
          <StickyTableCell>Ressurs</StickyTableCell>
          <RoleHeaders />
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {users.map((u) => (
          <TableRow key={u?.id}>
            <TableCell sx={{ width: '15%' }}>{getShortName(u?.name)}</TableCell>
            <RoleSums user={u} revisions={revisions} />
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

function RoleSums({ user, revisions }: { user: User; revisions: Revision[] }) {
  return (
    <>
      {Object.keys(roles).map((r) => {
        const key = r as keyof typeof roles;
        const count = revisions.reduce((acc, rev) => {
          const list = getListFromRoles(rev, key);
          return acc + (list?.some((x) => x?.id === user?.id) ? 1 : 0);
        }, 0);
        return (
          <TableCell key={key} align="center" sx={{ width: '3%' }}>
            {count || ''}
          </TableCell>
        );
      })}
    </>
  );
}

function getUsersFromRevisions(revisions: Revision[]): User[] {
  const allusers = getAllUsersWithRoles(revisions)
    .map((r) => r?.user)
    .filter((u) => !!u);
  const distinctUsers = allusers.filter((u, i) => allusers.findIndex((x) => x?.id === u?.id) === i);

  distinctUsers.sort((a, b) => {
    if (a?.name && b?.name && a?.name > b?.name) return 1;
    if (a?.name && b?.name && a?.name < b?.name) return -1;
    return 0;
  });

  return distinctUsers;
}
