import React, { FC } from 'react';
import { FormControlLabel, Switch, Tooltip, Grid2 as Grid } from '@mui/material';
import { selectBacklogFilter, setShowBfs, setShowBvn } from './filterSlice';
import { useDispatch, useSelector } from 'react-redux';

export const Filter: FC = () => {
  const { showBfs, showBvn } = useSelector(selectBacklogFilter);
  const dispatch = useDispatch();

  return (
    <Grid container spacing={2}>
      <Tooltip title="Vis anvisninger i Byggforskserien">
        <FormControlLabel control={<Switch onChange={() => dispatch(setShowBfs(!showBfs))} checked={showBfs} />} label="BFS" />
      </Tooltip>
      <Tooltip title="Vis blader i Byggebransjens Våtromsnorm">
        <FormControlLabel control={<Switch onChange={() => dispatch(setShowBvn(!showBvn))} checked={showBvn} />} label="BVN" />
      </Tooltip>
    </Grid>
  );
};
