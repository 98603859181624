import React, { createContext, FC, useContext } from 'react';

import { Typography, Button, Stack, Accordion, AccordionSummary, AccordionDetails, Divider, Box } from '@mui/material';
import { ArrowDropDown, Launch } from '@mui/icons-material';

import { useConfig } from '../env';
import moment from 'moment';
import { Guide } from './Details';
import { PublishType } from '../__generated__/graphql';
import { Unpacked } from '../graphQLTypes/types';
import { StyledPaper } from '../theme';
import { capitalizeFirstLetter } from '../utils/string';
import { formatDateOnly } from '../Formatters';
import { StyledLink } from '../Components';

export default function Releases({ guide }: { guide: Guide }) {
  const hasVersions = guide.versions && guide.versions.length > 0;
  return (
    <StyledPaper>
      <Typography variant="h5" component="h3" gutterBottom>
        Endringshistorikk
      </Typography>
      {hasVersions && <ReleaseList guide={guide} />}
      {!hasVersions && (
        <Typography color="textSecondary" variant="body2">
          Det finnes ingen endringshistorikk til anvisningen
        </Typography>
      )}
    </StyledPaper>
  );
}

type Change = Version & {
  edition: string | undefined;
};

function toPublicId(version: Version): string {
  return `${version.major}.${version.minor}`;
}

function toEdition(version: Version): string {
  return capitalizeFirstLetter(moment(version.createdAt).format('MMMM YYYY'));
}

type Version = NonNullable<Unpacked<Guide['versions']>>;

function ReleaseList({ guide }: { guide: Guide }) {
  var currentEdition: string | undefined = undefined;
  var currentChange: Change | undefined = undefined;
  const arr: Change[] = [];
  for (let index = 0; index < guide.versions!.length; index++) {
    const version = guide.versions![index];
    if (!version) continue;
    if (version.type === PublishType.Major) {
      currentEdition = toEdition(version);
    }
    var change = { edition: currentEdition, ...version };
    if (version.type !== PublishType.Bugfix) arr.push(change);
    else if (currentChange) {
      currentChange.releaseNotes = change.releaseNotes;
      currentChange.retractedAt = change.retractedAt;
      currentChange.retractedReason = change.retractedReason;
    }
    currentChange = change;
  }

  const versions = (guide.versions!.filter((x) => x) as Version[]) ?? [];
  versions.sort((a, b) => b.createdAt.localeCompare(a.createdAt));
  var editions = versions.filter((x) => x.type === PublishType.Major);

  return (
    <GuideDocIdContext.Provider value={guide.docId}>
      <Stack>
        <Stack display="grid" gridTemplateColumns={'1fr 1fr 1fr'} gap={1} paddingLeft={2} paddingRight={5}>
          <Typography fontWeight={500}>Utgave</Typography>
          <Typography fontWeight={500}>Version</Typography>
          <Typography fontWeight={500}>Dato</Typography>
        </Stack>
        {editions.map((edition, index) => (
          <EditionItem key={edition!.versionId} versions={versions.filter((x) => x.major == edition?.major)} defaultExpanded={index === 0} />
        ))}
      </Stack>
    </GuideDocIdContext.Provider>
  );
}

const GuideDocIdContext = createContext<number | undefined>(undefined);

const EditionItem: FC<{ versions: Version[]; defaultExpanded?: boolean }> = ({ versions, defaultExpanded }) => {
  const items: Version[] = [];
  let currentBugfix: Version | null = null;
  for (let index = 0; index < versions.length; index++) {
    const version = versions[index];
    if (version.type === PublishType.Bugfix) {
      currentBugfix = version;
    } else {
      if (currentBugfix) {
        items.push({
          ...version,
          releaseNotes: currentBugfix.releaseNotes,
          retractedAt: currentBugfix.retractedAt,
          retractedReason: currentBugfix.retractedReason,
          replacementGuides: currentBugfix.replacementGuides,
        });
        currentBugfix = null;
      } else {
        items.push(version);
      }
    }
  }

  if (items.length === 0) return null;
  const latest = items[0];
  if (items.length === 1) return <VersionItem version={latest} defaultExpanded={defaultExpanded} />;

  return (
    <Accordion defaultExpanded={defaultExpanded}>
      <AccordionSummary expandIcon={<ArrowDropDown />}>
        <VersionSummary version={latest} />
      </AccordionSummary>
      <AccordionDetails sx={{ paddingTop: 0 }}>
        <Stack>
          <VersionDetails version={latest} />
          {items.slice(1).map((version) => (
            <VersionItem key={version.versionId} version={version} />
          ))}
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};

const VersionItem: FC<{ version: Version; defaultExpanded?: boolean }> = ({ version, defaultExpanded }) => {
  return (
    <Accordion defaultExpanded={defaultExpanded}>
      <AccordionSummary expandIcon={<ArrowDropDown />}>
        <VersionSummary version={version} />
      </AccordionSummary>
      <AccordionDetails sx={{ paddingTop: 0 }}>
        <VersionDetails version={version} />
      </AccordionDetails>
    </Accordion>
  );
};

const VersionSummary: FC<{ version: Version }> = ({ version }) => {
  return (
    <Stack display="grid" gridTemplateColumns={'1fr 1fr 1fr'} width={'100%'} gap={1}>
      <Typography>{toEdition(version)}</Typography>
      <Typography>{toPublicId(version)}</Typography>
      <Typography>{formatDateOnly(version.createdAt)}</Typography>
    </Stack>
  );
};

const VersionDetails: FC<{ version: Version }> = ({ version }) => {
  const config = useConfig();
  const docId = useContext(GuideDocIdContext);

  return (
    <div>
      <Stack divider={<Divider orientation="horizontal" flexItem />} spacing={1}>
        {version.releaseNotes && (
          <div>
            <Typography fontWeight={500}>Hva er endret</Typography>
            <Typography paddingLeft={1}>{version.releaseNotes}</Typography>
          </div>
        )}
        {version.retractedAt && (
          <Stack>
            <Stack direction="row" spacing={1}>
              <Typography fontWeight="500">Tilbaketrukket</Typography>
              <Typography>{formatDateOnly(version.retractedAt)}</Typography>
            </Stack>
            {version.retractedReason && (
              <Stack>
                <Typography fontWeight={500}>Årsak</Typography>
                <Typography paddingLeft={1}>{version.retractedReason}</Typography>
              </Stack>
            )}
            {version.replacementGuides && version.replacementGuides.length > 0 && (
              <div>
                <Typography fontWeight={500}>Erstattet av</Typography>
                <Stack paddingLeft={1}>
                  {version.replacementGuides.map((guide) => (
                    <StyledLink to={`/guide/${guide?.id}`} key={guide?.id}>
                      <Typography>
                        {guide?.docName} {guide?.docTitle}
                      </Typography>
                    </StyledLink>
                  ))}
                </Stack>
              </div>
            )}
          </Stack>
        )}
      </Stack>
      <Box display="flex" justifyContent="flex-end">
        <Button
          title="Åpne i Byggforsk.no"
          href={`${config?.bksUrl}/dokument/${docId}?version=${toPublicId(version)}`}
          target="_blank"
          variant="text"
          color="secondary"
          size="small"
          endIcon={<Launch />}>
          Åpne i Byggforsk.no
        </Button>
      </Box>
    </div>
  );
};
