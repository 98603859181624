import { gql } from '../__generated__';

gql(/* GraphQL */ `
  fragment UserItem on User {
    subjectId
    name
  }
`);

gql(/* GraphQL */ `
  fragment PlanningItem on PlanningValue {
    value
    score
    boost
  }
`);

gql(/* GraphQL */ `
  fragment PlanningScores on PlanningScores {
    edition {
      ...PlanningItem
    }
    notices {
      ...PlanningItem
    }
    pageViews {
      value
      score
      boost
      placement {
        index
        total
      }
    }
    needForRevision {
      ...PlanningItem
    }
  }
`);

gql(/* GraphQL */ `
  fragment GuideOverviewItem on GuideModel {
    id
    docId
    docNumber
    docName
    docTitle
    docVersion
    hasChangesSinceLastPublish
    status
    owner {
      ...UserItem
    }
    mainCategory {
      id
      title
    }
    lastPublishedAt
    lastChangedAt
    archivedAt
    edition
    version
    sortableEdition
    ongoingRevision {
      id
    }
  }
`);

gql(/* GraphQL */ `
  fragment GuideItem on GuideModel {
    id
    docId
    docNumber
    docTitle
    docName
    docVersion
    docFileName
    docUpdated
    contentId
    series
    description
    needForRevision
    needForRevisionComment
    planningScores {
      ...PlanningScores
    }
    ongoingRevision {
      id
      type
      scheduledEndDate
      canceledAt
      completedAt
      checklist {
        id
        numberOfTasks
        completedTasks
        milestones {
          name
          tasks {
            completed
          }
        }
      }
    }
    hasChangesSinceLastPublish
    hasNewContent
    status
    authors {
      id
      name
    }
    partner {
      id
      name
    }
    classificationItemId
    classificationLabel
    hasUserFeedback
    projectLeader
    mainCategory {
      id
      title
    }
    owner {
      ...UserItem
    }
    mainCategory {
      title
    }
    releases {
      id
      releaseNotes
      createdAt
    }
    paragraphs {
      id
      regulation
      number
      title
    }
    standards {
      id
      name
      title
    }
    categoryIds {
      categoryId
      relevance
    }
    categories {
      id
      title
    }
    images {
      id
      number
      name
      files {
        imageFileId
        fileName
        uploadedAt
      }
    }
    attachments {
      id
      name
      uploaded
      createdAt
    }
    notices {
      id
      title
      notice
      createdAt
      createdBy {
        subjectId
        name
      }
      deletedBy {
        subjectId
        name
      }
      deletedAt
      guide {
        id
      }
    }
    replacementGuides {
      ...GuideOverviewItem
    }
    versions {
      versionId
      major
      minor
      bugfix
      releaseNotes
      type
      createdAt
      retractedAt
      retractedReason
      replacementGuides {
        ...GuideOverviewItem
      }
    }
    tasks {
      ...TaskItem
    }
    comments {
      ...GuideCommentItem
    }
    shareablePreviewLink
    releaseNotes
    guideLinks {
      ...GuideOverviewItem
    }
    books
    useCmsContent
    hearing {
      ...HearingItem
    }
  }
`);

export const GET_GUIDE = gql(/* GraphQL */ `
  query GetGuide($id: UUID!) {
    guide: guide(id: $id) {
      ...GuideItem
    }
  }
`);

export const GET_GUIDES = gql(/* GraphQL */ `
  query GetGuides {
    guides {
      ...GuideOverviewItem
    }
  }
`);

export const GET_GUIDE_AND_LINKS = gql(/* GraphQL */ `
  query GetGuidesAndLinks($id: UUID!) {
    guide(id: $id) {
      ...GuideItem
    }
    guides(order: { docName: ASC }) {
      ...GuideOverviewItem
    }
  }
`);

export const SET_PROJECT_LEADER = gql(/* GraphQL */ `
  mutation GuideSetProjectLeader($input: GuideSetProjectLeaderInput!) {
    guideSetProjectLeader(input: $input) {
      guideModel {
        id
        projectLeader
      }
    }
  }
`);

export const UPDATE_AUTHORS = gql(/* GraphQL */ `
  mutation GuideSetAuthors($input: GuideSetAuthorsInput!) {
    guideSetAuthors(input: $input) {
      guideModel {
        id
        authors {
          id
          name
        }
      }
    }
  }
`);

export const ADD_LINK = gql(/* GraphQL */ `
  mutation GuideAddGuideLink($input: GuideAddGuideLinkInput!) {
    guideAddGuideLink(input: $input) {
      guideModel {
        id
        hasChangesSinceLastPublish
        guideLinks {
          id
          docVersion
        }
      }
    }
  }
`);

export const REMOVE_LINK = gql(/* GraphQL */ `
  mutation GuideRemoveGuideLink($input: GuideRemoveGuideLinkInput!) {
    guideRemoveGuideLink(input: $input) {
      guideModel {
        id
        hasChangesSinceLastPublish
        guideLinks {
          id
          docVersion
        }
      }
    }
  }
`);

export const ADD_PARTNER = gql(/* GraphQL */ `
  mutation GuideAddPartner($input: GuideAddPartnerInput!) {
    guideAddPartner(input: $input) {
      guideModel {
        id
        partner {
          id
          name
        }
        hasChangesSinceLastPublish
      }
    }
  }
`);

export const REMOVE_PARTNER = gql(/* GraphQL */ `
  mutation GuideRemovePartner($input: GuideRemovePartnerInput!) {
    guideRemovePartner(input: $input) {
      guideModel {
        id
        partner {
          id
          name
        }
        hasChangesSinceLastPublish
      }
    }
  }
`);

export const PUBLISH_GUIDE = gql(/* GraphQL */ `
  mutation GuidePublish($input: GuidePublishInput!) {
    guidePublish(input: $input) {
      guideModel {
        ...GuideItem
      }
    }
  }
`);

export const ARCHIVE_GUIDE = gql(/* GraphQL */ `
  mutation GuideArchive($input: GuideArchiveInput!) {
    guideArchive(input: $input) {
      guideModel {
        ...GuideItem
      }
    }
  }
`);

export const RESTORE_GUIDE = gql(/* GraphQL */ `
  mutation GuideRestore($input: GuideRestoreInput!) {
    guideRestore(input: $input) {
      guideModel {
        id
        status
        hasChangesSinceLastPublish
        replacementGuides {
          ...GuideOverviewItem
        }
      }
    }
  }
`);

export const CLEAR_CONTENT = gql(/* GraphQL */ `
  mutation GuideClearContent($input: GuideClearContentInput!) {
    guideClearContent(input: $input) {
      guideModel {
        id
        docVersion
        docFileName
        docUpdated
        hasNewContent
        hasChangesSinceLastPublish
        images {
          id
          number
          name
          files {
            imageFileId
            fileName
            uploadedAt
          }
        }
        attachments {
          id
          name
          uploaded
          createdAt
        }
      }
    }
  }
`);

export const UPLOAD_CONTENT = gql(/* GraphQL */ `
  mutation GuideUploadContent($input: GuideUploadContentInput!) {
    guideUploadContent(input: $input) {
      guideModel {
        id
        docVersion
        docFileName
        docUpdated
        hasNewContent
        hasChangesSinceLastPublish
        images {
          id
          number
          name
          files {
            imageFileId
            fileName
            uploadedAt
          }
        }
        attachments {
          id
          name
          uploaded
          createdAt
        }
      }
    }
  }
`);

export const UPLOAD_ATTACHMENT = gql(/* GraphQL */ `
  mutation GuideUploadAttachment($input: GuideUploadAttachmentInput!) {
    guideUploadAttachment(input: $input) {
      guideModel {
        id
        hasChangesSinceLastPublish
        attachments {
          id
          name
          uploaded
          createdAt
        }
      }
    }
  }
`);

export const REMOVE_ATTACHMENT = gql(/* GraphQL */ `
  mutation GuideRemoveAttachment($input: GuideRemoveAttachmentInput!) {
    guideRemoveAttachment(input: $input) {
      guideModel {
        id
        hasChangesSinceLastPublish
        attachments {
          id
          name
          uploaded
          createdAt
        }
      }
    }
  }
`);

export const UPLOAD_IMAGE_FILE = gql(/* GraphQL */ `
  mutation GuideUploadImageFile($input: GuideUploadImageFileInput!) {
    guideUploadImageFile(input: $input) {
      guideModel {
        id
        hasChangesSinceLastPublish
        images {
          id
          name
          number
          files {
            imageFileId
            fileName
          }
        }
      }
    }
  }
`);

export const REMOVE_IMAGE_FILE = gql(/* GraphQL */ `
  mutation GuideRemoveImageFile($input: GuideRemoveImageFileInput!) {
    guideRemoveImageFile(input: $input) {
      guideModel {
        id
        hasChangesSinceLastPublish
        images {
          id
          name
          number
          files {
            imageFileId
            fileName
          }
        }
      }
    }
  }
`);

export const UPDATE_TITLE = gql(/* GraphQL */ `
  mutation GuideUpdateTitle($input: GuideUpdateTitleInput!) {
    guideUpdateTitle(input: $input) {
      guideModel {
        id
        docTitle
        hasChangesSinceLastPublish
      }
    }
  }
`);

export const DELETE = gql(/* GraphQL */ `
  mutation GuideDelete($input: GuideDeleteInput!) {
    guideDelete(input: $input) {
      guideModel {
        id
      }
    }
  }
`);

export const UNDO = gql(/* GraphQL */ `
  mutation GuideUndoChanges($input: GuideUndoChangesInput!) {
    guideUndoChanges(input: $input) {
      guideModel {
        ...GuideItem
      }
    }
  }
`);

export const GET_CHANGES_SINCE_LAST_PUBLISH = gql(/* GraphQL */ `
  query GetGuideChangesSinceLastPublish($id: UUID!) {
    guide(id: $id) {
      id
      trackingChanges {
        description
        isMajor
      }
    }
  }
`);

export const GET_CHANGES = gql(/* GraphQL */ `
  query GetGuideChanges($id: UUID!, $first: Int) {
    changeModel: guideChanges(id: $id, first: $first, order: { id: DESC }) {
      totalCount
      nodes {
        id
        createdAt
        createdByName
        description
        type
        publishEvent
      }
    }
  }
`);

export const ADD_NEW_GUIDE = gql(/* GraphQL */ `
  mutation GuideCreate($input: GuideCreateInput!) {
    guideCreate(input: $input) {
      guideModel {
        ...GuideOverviewItem
      }
      errors {
        code: __typename
        ... on GuideAlreadyExistsError {
          message
        }
      }
    }
  }
`);

export const UPDATE_RELEASENOTES = gql(/* GraphQL */ `
  mutation GuideUpdateReleaseNotes($input: GuideUpdateReleaseNotesInput!) {
    guideUpdateReleaseNotes(input: $input) {
      guideModel {
        id
        releaseNotes
        hasChangesSinceLastPublish
      }
    }
  }
`);

export const UPDATE_MAIN_CATEGORY = gql(/* GraphQL */ `
  mutation GuideSetMainCategory($input: GuideSetMainCategoryInput!) {
    guideSetMainCategory(input: $input) {
      guideModel {
        id
        mainCategory {
          id
        }
      }
    }
  }
`);

export const CREATE_SHAREABLE_LINK = gql(/* GraphQL */ `
  mutation GuideCreateShareablePreviewLink($input: GuideCreateShareablePreviewLinkInput!) {
    guideCreateShareablePreviewLink(input: $input) {
      guideModel {
        id
        shareablePreviewLink
      }
    }
  }
`);

export const DELETE_SHAREABLE_LINK = gql(/* GraphQL */ `
  mutation GuideDeleteShareablePreviewLink($input: GuideDeleteShareablePreviewLinkInput!) {
    guideDeleteShareablePreviewLink(input: $input) {
      guideModel {
        id
        shareablePreviewLink
      }
    }
  }
`);

export const USE_CMS_CONTENT = gql(/* GraphQL */ `
  mutation GuideUseCmsContent($input: GuideUseCmsContentInput!) {
    guideUseCmsContent(input: $input) {
      guideModel {
        id
        useCmsContent
        guideLinks {
          id
        }
        standards {
          id
        }
      }
    }
  }
`);

export const START_REVISION = gql(/* GraphQL */ `
  mutation GuideStartRevision($input: GuideStartRevisionInput!) {
    guideStartRevision(input: $input) {
      guideModel {
        id
        ongoingRevision {
          id
        }
      }
    }
  }
`);

gql(/* GraphQL */ `
  fragment ParagraphItem on ParagraphModel {
    id
    regulation
    number
    title
  }
`);

export const GET_GUIDE_AND_PARAGRAPHS = gql(/* GraphQL */ `
  query GetGuideAndParagraphs($id: UUID!) {
    guide(id: $id) {
      id
      docId
      docName
      docTitle
      paragraphs {
        ...ParagraphItem
      }
    }

    paragraphs {
      ...ParagraphItem
    }
  }
`);

export const ADD_PARAGRAPH = gql(/* GraphQL */ `
  mutation GuideAddParagraph($input: GuideAddParagraphInput!) {
    guideAddParagraph(input: $input) {
      guideModel {
        id
        hasChangesSinceLastPublish
        paragraphs {
          ...ParagraphItem
        }
      }
    }
  }
`);

export const REMOVE_PARAGRAPH = gql(/* GraphQL */ `
  mutation GuideRemoveParagraph($input: GuideRemoveParagraphInput!) {
    guideRemoveParagraph(input: $input) {
      guideModel {
        id
        hasChangesSinceLastPublish
        paragraphs {
          ...ParagraphItem
        }
      }
    }
  }
`);

export const GET_RELEASES = gql(/* GraphQL */ `
  query GetReleases {
    releases: guideReleases {
      id
      guideId
      docNumber
      docName
      docId
      title
      publishedAt
      archivedAt
      projectLeader
      version
    }
  }
`);

export const GET_PAGEVIEWS_PER_LICENSE = gql(/* GraphQL */ `
  query GetPageViewsPerLicenseForGuide($id: UUID!, $periodStart: DateTime!, $periodEnd: DateTime!, $count: Int!) {
    guide(id: $id) {
      id
      pageviewsPerLicense(periodStart: $periodStart, periodEnd: $periodEnd, count: $count) {
        ...DocumentViewItem
      }
    }
  }
`);

export const GET_APPS = gql(/* GraphQL */ `
  query GetApps {
    apps {
      id
      appId
      version
      name
      description
    }
  }
`);
