import { GuideStatus } from '../__generated__/graphql';

export const getGuideStatusDescription = (guideStatus: GuideStatus, hasChangesSinceLastPublish: boolean, hasRevision: boolean) => {
  if (guideStatus === GuideStatus.Planned) return 'Ny';

  if (hasChangesSinceLastPublish) {
    return 'Upubliserte endringer';
  }

  switch (guideStatus) {
    case GuideStatus.Active:
      return hasRevision ? 'Under revisjon' : 'Publisert';
    case GuideStatus.Expired:
      return 'Tilbaketrukket';
    default:
      return undefined;
  }
};
