export function capitalizeFirstLetter(string: string) {
  if (!string) {
    return '';
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
}

/**
 * Create a short name from a full name
 * If several names are given, only the first name and the first letter of the other names are included
 * If more than one name is given, 'm.fl.' is added to the end
 *
 *
 * @param name - One or more names
 * @returns A short name
 *
 */
export const getShortName = (...names: (string | null | undefined)[]) => {
  const nonNullNames = names.filter((n) => !!n);
  if (nonNullNames.length < 1) {
    return null;
  }
  const name = nonNullNames[0] as string;

  const nameparts = name.split(' ');
  const firstName = nameparts.shift();
  const shortName = firstName + ' ' + nameparts.map((n) => n[0]).join(' ');
  return nonNullNames.length === 1 ? shortName : shortName + ' m.fl.';
};
